<template>
  <div class="project">
    
    <caseProjectList 
    :listType='listType'
    :topForm='topForm'
    :listData='listData'
    @routerPush='routerPush'
    @createCaseProject='createCaseProject'
    ></caseProjectList>
    <myDialog 
    @dialogAfterLeave='afterLeave'
    :title='oppTitle'
    :isShow='oppIsShow'
    :closeDialog='closeDialog'
    >
      <div>
        <div class="radioGroup" v-show="isShowRadioGroup">
          <el-radio-group v-model="radio" @change='changeRadio'>
            <el-radio :label="1">专项服务</el-radio>
            <el-radio :label="2">顾问单位</el-radio>
            <el-radio :label="3">其他</el-radio>
          </el-radio-group>
        </div>
        <div class="formBaseInfo" v-show="isShowFormBaseInfo">
          formBaseInfo
        </div>
      </div>
    </myDialog>
  </div>
</template>
<script>
import {defineComponent,ref} from 'vue';
import { useRouter } from 'vue-router';
import caseProjectList from '@/components/caseProjectList.vue';
import myDialog from '@/components/myDialog.vue';
// 列表模板相关
const useCaseProjectList = (oppIsShow,isShowRadioGroup)=>{
  const router = useRouter();
  const routerPush = val => {
    router.push(val.path)
  };
  const createCaseProject = () => {
    oppIsShow.value = true;
    isShowRadioGroup.value = true;
  };
  return {
    routerPush,
    createCaseProject
  }
};
// 新建项目弹框相关
const useCreateCase = ()=>{
  let oppIsShow = ref(false);
  let oppTitle = ref('请选择项目类型');
  let radio = ref(null);
  let isShowRadioGroup = ref(true);
  let isShowFormBaseInfo = ref(false);
  const changeRadio = ()=>{
    isShowRadioGroup.value = false;
    oppTitle.value = '填写项目基本信息';
    isShowFormBaseInfo.value = true;
  };
  const clickCloseBtn = ()=>{
    console.log('clickCloseBtn')
  };
  const resetOpp = ()=>{
    radio.value = null;
    isShowRadioGroup.value = false;
    isShowFormBaseInfo.value = false;
  };
  const closeDialog = ()=>{
    oppIsShow.value = false;
  };
  const afterLeave = (v)=>{
    resetOpp()
  }
  return {
    afterLeave,
    oppIsShow,
    oppTitle,
    radio,
    isShowRadioGroup,
    isShowFormBaseInfo,
    changeRadio,
    clickCloseBtn,
    resetOpp,
    closeDialog,
  }
};
export default defineComponent({
  name:"project",
  components:{
    caseProjectList,
    myDialog
  },
  setup(){
    const {afterLeave,oppIsShow,oppTitle,radio,isShowRadioGroup,isShowFormBaseInfo,changeRadio,clickCloseBtn,resetOpp,closeDialog} = useCreateCase();
    const {routerPush,createCaseProject} = useCaseProjectList(oppIsShow,isShowRadioGroup)
    return {
      routerPush,createCaseProject,
      afterLeave,oppIsShow,oppTitle,radio,isShowRadioGroup,isShowFormBaseInfo,changeRadio,clickCloseBtn,resetOpp,closeDialog,
    }
  },
  
  data(){
    return {
      topForm:[{
        name:'状态',
        options: [{
          value: 0,
          label: '全部'
          },{
            value: 1,
            label: '进行中'
          },{
            value: 2,
            label: '最长的名字字段在场'
          }],
          selectVal:''
        },{
        name:'类型',
        options: [{
          value: 0,
          label: '全部'
          },{
            value: 1,
            label: '进行中'
          },{
            value: 2,
            label: '最长的名字字段在场'
          }],
          selectVal:''
        }
      ],
      listType:'project',
      listData:{
        data:[
          {
            id:1,
            date:'2021-01-12',
            order:'苏永（专）2021 第 101 号',
            title:'张三入室盗窃张三入室盗窃杀人案张三入室盗窃杀人案杀人案',
            classType:'刑事辩护',
            lawyer:'张律师',
            status:'进行中'
          },
        ]
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.radioGroup{
  padding-bottom:30px;
  .el-radio-group{
    display: flex;
    align-items: center;
    width: 480px;
    padding-left: 45px;
    flex-wrap: wrap;
    margin: auto;
    .el-radio{
      width:33%;
      margin-right: 0;
      margin-top:25px;
    }
  }
}
</style>